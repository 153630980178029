<script setup lang="ts">
import { useMagicKeys } from '@vueuse/core'
import Dialog from 'primevue/dialog'
import { defineAsyncComponent, watch } from 'vue'
import { isDebugToolsEnabled } from './debugtools'

const { Ctrl_Shift_D } = useMagicKeys()

const DebugContainer = defineAsyncComponent(() => import('./DebugToolsContainer.vue'))
watch(() => Ctrl_Shift_D.value, (value) => {
  if (value)
    isDebugToolsEnabled.value = !isDebugToolsEnabled.value
})
</script>

<template>
  <Dialog
    v-model:visible="isDebugToolsEnabled"
    :pt="{
      content: '!p-0 ',
    }"
    dismissable-mask
    modal
    header="Debug Tools"
    class="debug-modal"
    :style="{ width: '90rem' }"
    :breakpoints="{ '1199px': '90vw', '575px': '90vw' }"
  >
    <template v-if="isDebugToolsEnabled">
      <DebugContainer />
    </template>
  </Dialog>
</template>

<style>
.debug-modal .p-dialog-header {
  padding: 1rem 1rem !important;
}
</style>
