<script setup lang="ts">
import 'primeicons/primeicons.css'
import { setYupLocales } from '@core/plugins/plugin-yup.ts'
import DebugTools from '@components/debugtools/DebugTools.vue'

const { t } = useI18n()
setYupLocales(t)
useHead({
  title: 'Onvi - Stel je pakket samen',
  meta: [
    { name: 'description', content: 'Onvi | Internet, Televisie, Mobiel' },
    {
      name: 'theme-color',
      content: computed(() => isDark.value ? '#F7A602' : '#ffffff'),
    },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: computed(() => preferredDark.value ? '/favicon.ico' : '/favicon.ico'),
    },
  ],
})
</script>

<template>
  <Toast />
  <DebugTools />
  <RouterView />
</template>

<style scoped>

</style>
