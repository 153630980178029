import { handleHotUpdate, routes } from 'vue-router/auto-routes'
import { createRouter, createWebHistory } from 'vue-router'
import app from '@core/configApp.ts'
import * as Sentry from '@sentry/vue'

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})
if (import.meta.hot) {
  handleHotUpdate(router)
}
if (import.meta.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://951104df1a7a4bdb8cb73646e1fd9b38@sentry.vitrion.dev/34',
    integrations: [Sentry.browserTracingIntegration({ router })],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}
app.use(router)
