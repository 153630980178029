import app from '@core/configApp.ts'

/*
 * All i18n resources specified in the plugin `include` option can be loaded
 * at once using the import syntax
 */
import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'

export const i18n = createI18n({
  locale: 'nl',
  messages,
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
      currencyNoSymbol: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
    nl: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
      currencyNoSymbol: {
        style: 'decimal',
        separator: ',',
        decimal: ',',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
})

export function getAvailableLocales() {
  return Object.keys((i18n.global as any).value?.messages || {})
}

app.use(i18n)
